import React, { Component } from "react";
import "./App.css";
import Home from "./home/home";
import Login from "./login/login";
import Cookies from 'universal-cookie';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Button } from "react-bootstrap";
//require('dotenv').config()
const axios = require('axios');
const backendURL = window._env_.REACT_APP_BACKEND_URL || "http://localhost:3001";
const cookies = new Cookies();

class App extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
	      login: false,
	      user: "",
        logData: false,
        loginInfo: cookies.get('loginInfo'),
		person: "",
		userData:"",
		userDisplayName: "",
	    };
	    this.handleLogin = this.handleLogin.bind(this)
	    this.signout = this.signout.bind(this)
	  }
	  
   handleLogin(loginSuccess, userData) {
    const loginData = userData.loginData;
		this.setCookies(loginData);
	  if(loginSuccess) {
		 this.setState({
			login: loginSuccess,
			userData: userData,
			logData: userData.loggedIn,
			userDisplayName: userData.id,
			user: userData.user,
			redirect: true
	    })
	    } 	
	 }
	
   handleSubmit() {
		const self = this;
		axios.post(backendURL+'/login', {
			username: "",
			password: "",
		  	loginInfo: this.state.loginInfo,
		})
		.then(function (response) {
			if(response.status === 200) {
				self.handleLogin(true, response.data);
				}
		})
		.catch(function (error) {
			console.log(error);
		});
		}
		
    setCookies(loginData) {
		return cookies.set('loginInfo', loginData, { path: '/', maxAge: 3600});
	}	 

	removeCookies() {
		return cookies.remove('loginInfo');
	}	 

	 
	signout(){

     this.removeCookies();
		 this.setState({
			login: false,
			userData: null,
    		logData: false,
	      	user: "",
        	loginInfo: "",
			person: "",
			userDisplayName: "",
		 })
	 }
   
   componentDidMount() {
		if(this.state.loginInfo) {
			this.handleSubmit();
		}
	}
	render() {
    return (
      <div className="App">
      <Router>
          <div>
            <ul>
              <div className="extras">
              { this.state.logData &&
                <Button onClick={this.signout}>Kirjaudu ulos</Button>
              }
              </div>
              <div className="clear"></div>
            </ul>
            
            <hr />
     
            {!this.state.logData && 
              <Route exact path="/" render={(props) => <Login loginHandler={this.handleLogin} /> } />
            }
            {this.state.logData && 
              <Route exact path="/" render={(props) => 
			  <Home
                loggedInUser={this.state.user} 
				loggedInName= {this.state.userDisplayName}
                loggedIn={this.state.login} 
              /> } />
            }
          </div>
        </Router>
		</div>
		);
	}
}

export default App;
