import React, { Component } from "react";
import { Button, FormGroup, FormControl } from "react-bootstrap";
const axios = require('axios');
const backendURL = window._env_.REACT_APP_BACKEND_URL || "http://localhost:3001";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      temp: ""
    };
  }
  
  validateForm() {
    return this.state.username.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = event => {
	  const self = this;
	  axios.post(backendURL+'/login', {
		    username: this.state.username,
		    password: this.state.password
		  })
		  .then(function (response) {
        
			if(response.status === 200) {
		    	  self.props.loginHandler(true, response.data);
		    	} 
		  })
		  .catch(function (error) {
			if(error.response && error.response.status === 404) { 
		    	    self.setState({
			      loginError: true,
			      message: "Käyttäjää ei löydy"
			    });
		    	} else {
		    	  self.setState({
			    loginError: true,
			    message: "Väärä käyttäjätunnus ja salasana"
          
			  });
		    	}
		  });
    event.preventDefault();
  }

  render() {

    return (
      <div className="Login">
        { this.state.loginError && <div>{this.state.message}</div>}
        <h2 className="loginText">Kirjaudu sisään</h2>
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="username">
           Käyttäjätunnus
            <FormControl
              autoFocus
              type="username"
              value={this.state.username}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="password">
            Salasana
            <FormControl
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>
          <Button
            style={{ width: "40%" }}
            block
            disabled={!this.validateForm()}
            type="submit"
          >
            Kirjaudu
          </Button>
        </form>
      </div>
    );
  }
}
