import React, { Component } from "react";
import { Button, FormControl, Form, Spinner, Toast } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Cookies from 'universal-cookie';
//require('dotenv').config()
const cookies = new Cookies();
const axios = require('axios');
const apiLog = "api_log";
const backendURL = window._env_.REACT_APP_BACKEND_URL || "http://localhost:3001";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      host: '',
      MAC: '',
      sr: [],
      laite: '',
      laitetarkenne: '',
      tyypintarkenne: '',
      merkki: [],
      data: [],
      datasr: [],
      srArray: [],
      srDetails: [],
      arrFinal: [],
      isLoading: true,
      singleSelections: [],
      singleSelectionsSR: [],
      startDate: new Date(),
      selected: "",
      xml:"",
      valittu: false,
      valittu2: false,
      showToast: false,
      toastContent: {
        title: "",
        message: "",
        style: ""
      },
      login: false,
      user: "",
      logData: false,
      loginInfo: cookies.get('loginInfo'),
      formType: "",
      user_final: "",
      location_final: "",
      location_accurate_final: "",
      cost_center_final: ""
    };
      this.handleChangeSerial = this.handleChangeSerial.bind(this);
      this.handleChangeHost = this.handleChangeHost.bind(this);
      this.handleChangeMAC = this.handleChangeMAC.bind(this);
      this.handleChangeSR = this.handleChangeSR.bind(this);
      this.handleChangeLaite = this.handleChangeLaite.bind(this);
      this.handleChangeLaiteTarkenne = this.handleChangeLaiteTarkenne.bind(this);
      this.handleChangeTyypinTarkenne = this.handleChangeTyypinTarkenne.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChangeSerial(event) {
      this.setState({value: event.target.value});
    }
    handleChangeHost(event) {
      this.setState({host: event.target.value});
    }
    handleChangeMAC(event) {
      this.setState({MAC: event.target.value});
    }
    handleChangeSR(event) {
      this.setState({sr: event.target.value});
    }
    handleChangeLaite (e) {
      if (e.target.value === "Työasema") {
        this.setState({
          laite: e.target.value,
          formType: "workstation"
        });
      }
      else if (e.target.value === "Tulostin") {
        this.setState({
          laite: e.target.value,
          formType: "printer"
        });
      }
      else if (e.target.value === "Näyttö") {
        this.setState({
          laite: e.target.value,
          formType: "other_device"
        });
      }
      else if (e.target.value === "Telakka") {
        this.setState({
          laite: e.target.value,
          formType: "other_device"
        });
      }
      else if (e.target.value === "AV-laite") {
        this.setState({
          laite: e.target.value,
          formType: "other_device"
        });
      }
      else if (e.target.value === "Mobiililaite") {
        this.setState({
          laite: e.target.value,
          formType: "mobile_device"
        });
      }
      else if (e.target.value === "empty") {
        this.setState({
          laite: '',
          formType: ""
        });
      }
    }
    handleChangeLaiteTarkenne (e) {
      this.setState({ laitetarkenne: e.target.value });
    }
    handleChangeTyypinTarkenne (e) {
      this.setState({ tyypintarkenne: e.target.value });
    }
    setSingleSelections = (event) => {
      if (typeof event[0] === 'object') {
        this.state.valittu = true;
        this.setState({
          singleSelections: event,
          merkki: event[0].label
          })
        }
      else {
        this.state.valittu = true;
        this.setState({
          singleSelections: event,
          merkki: event[0]
        })
      }
    }
    setSingleSelectionsSR = (event) => {
      this.state.srDetails.map(number => {
        if (number[0] === event[0]) {
          this.setState({
            user_final: number[1],
            location_final: number[2],
            location_accurate_final: number[3],
            cost_center_final: number[4]
          })
        }
      })
        this.state.valittu2 = true;
        this.setState({
          singleSelectionsSR: event,
          sr: event[0]
        })
    }

    setStartDate = (event) => {
      this.setState({
        startDate: event
      })
    }
    addNewAttributeValuePair(xmldoc,attribute,value){
      var attributeElement = xmldoc.createElement("attribute");
      attributeElement.setAttribute("code",attribute);
      var valueElement = xmldoc.createElement("value");
      valueElement.innerHTML = value;
      attributeElement.appendChild(valueElement);
      return attributeElement;
    }
    handleSubmit(event) {
      if (this.state.value === "") {
        alert("Korjaa sarjanumero")
        event.preventDefault();
      }
      else if ((this.state.laite !== "Mobiililaite" && this.state.laite !== "AV-laite") && (this.state.host === "")) {
        alert("Korjaa host-name");
        event.preventDefault();
      }
      else if ((this.state.sr === "undefined") || (this.state.sr === undefined) || (this.state.sr === null) || (this.state.sr === "") || (this.state.valittu2 === false)) {
        alert("Lisää SR-numero tai" + '\n' +
         "Klikkaa SR-numeroa lukitaksesi valinnan")
        event.preventDefault();
      }
      else if ((this.state.laite === "empty") || (this.state.laite === "")) {
        alert("Korjaa laitteen tyyppi")
        event.preventDefault();
      }
      else if ((!(this.state.laite === "Näyttö" || this.state.laite === "Mobiililaite" || this.state.laite === "Telakka" || this.state.laite === "AV-laite")) && ((this.state.laitetarkenne === "empty") || (this.state.laitetarkenne === ""))) {
        alert("Korjaa laitteen tyypin tarkenne")
        event.preventDefault();
      }
      else if ((this.state.merkki === "undefined") || (this.state.merkki === undefined) || (this.state.merkki === "") || (this.state.valittu === false)) {
        alert("Korjaa laitteen merkki")
        event.preventDefault();
      }
      else {
        
        var typeName;
        axios.get(backendURL+'/serialnumber_check/'+this.state.value.replaceAll("/", "__DANGEROUSSLASH__"),{
          headers: {
            loginInfo: this.state.loginInfo
          }
        })
        .then((data) => {

          typeName = JSON.stringify(data.data[0]).replaceAll('"','').toLowerCase();

        }).finally(()=>{
          if(typeName.toLowerCase() !== this.state.laite.toLowerCase() && typeName){

            alert("Järjestelmästä löytyy jo "+typeName+'\n'+" kyseisellä sarjanumerolla");
            event.preventDefault();
            return
          }

          //alert("Nyt voidaan lähettää.");
          

          //var newxmlString = "";
          var myXML = document.implementation.createDocument("","",null);
          var entityset = myXML.createElement("entityset");
          var entity = myXML.createElement("entity");
          var template = myXML.createElement("template");
          template.setAttribute("code",this.state.formType);
          entity.appendChild(template);
          if (this.state.laite === "Tulostin") {
            entity.appendChild(this.addNewAttributeValuePair(myXML,"printer_type",this.state.laitetarkenne));
            entity.appendChild(this.addNewAttributeValuePair(myXML,"printer_model",this.state.merkki));
            entity.appendChild(this.addNewAttributeValuePair(myXML,"'device_sticker'",this.state.host));
            entity.appendChild(this.addNewAttributeValuePair(myXML,"device_serialnumber",this.state.value));
            entity.appendChild(this.addNewAttributeValuePair(myXML,"printer_type_additional",this.state.tyypintarkenne));

            if(this.state.laitetarkenne === "Verkkokirjoitin"){
              entity.appendChild(this.addNewAttributeValuePair(myXML,"mac",this.state.MAC));
            }
          }
          else if (this.state.laite === "Näyttö") {
            entity.appendChild(this.addNewAttributeValuePair(myXML,"other_device_model",this.state.merkki));
            entity.appendChild(this.addNewAttributeValuePair(myXML,"other_device_type","Näyttö"));
            entity.appendChild(this.addNewAttributeValuePair(myXML,"device_sticker",this.state.host));  
            entity.appendChild(this.addNewAttributeValuePair(myXML,"device_serialnumber",this.state.value));
          }
          else if (this.state.laite === "AV-laite") {
            entity.appendChild(this.addNewAttributeValuePair(myXML,"other_device_model",this.state.merkki));
            entity.appendChild(this.addNewAttributeValuePair(myXML,"other_device_type","AV-laite"));
            entity.appendChild(this.addNewAttributeValuePair(myXML,"other_device_additional_type",this.state.tyypintarkenne));
            entity.appendChild(this.addNewAttributeValuePair(myXML,"device_sticker",this.state.host));  
            entity.appendChild(this.addNewAttributeValuePair(myXML,"device_serialnumber",this.state.value));
          }
          else if (this.state.laite === "Telakka") {
            entity.appendChild(this.addNewAttributeValuePair(myXML,"other_device_model",this.state.merkki));
            entity.appendChild(this.addNewAttributeValuePair(myXML,"other_device_type","Muu laite (ei verkossa)"));
            entity.appendChild(this.addNewAttributeValuePair(myXML,"device_sticker",this.state.host)); 
            entity.appendChild(this.addNewAttributeValuePair(myXML,"other_device_additional_type","Telakka"));
            entity.appendChild(this.addNewAttributeValuePair(myXML,"device_serialnumber",this.state.value));
          }
          else if (this.state.laite === "Työasema") {
            entity.appendChild(this.addNewAttributeValuePair(myXML,"workstation_model",this.state.merkki));
            entity.appendChild(this.addNewAttributeValuePair(myXML,"workstation_type",this.state.laitetarkenne));
            entity.appendChild(this.addNewAttributeValuePair(myXML,"host_name",this.state.host));
            entity.appendChild(this.addNewAttributeValuePair(myXML,"device_serialnumber",this.state.value));
            entity.appendChild(this.addNewAttributeValuePair(myXML,"workstation_model_text",this.state.merkki)); 
          }
          else if (this.state.laite === "Mobiililaite") {
            entity.appendChild(this.addNewAttributeValuePair(myXML,"mobile_device_model",this.state.merkki));
            entity.appendChild(this.addNewAttributeValuePair(myXML,"device_type",""));
            entity.appendChild(this.addNewAttributeValuePair(myXML,"device_sticker",""));
            entity.appendChild(this.addNewAttributeValuePair(myXML,"imei",this.state.value));
          }
          entity.appendChild(this.addNewAttributeValuePair(myXML,"supplier",this.props.loggedInName));
          entity.appendChild(this.addNewAttributeValuePair(myXML,"purchase_order",this.state.sr));
          entity.appendChild(this.addNewAttributeValuePair(myXML,"additional_information",this.state.sr));
          entity.appendChild(this.addNewAttributeValuePair(myXML,"start_of_use",this.state.startDate));
          entity.appendChild(this.addNewAttributeValuePair(myXML,"arrival_date",this.state.startDate));

          entityset.appendChild(entity);
          myXML.appendChild(entityset);
          var newxmlString = "<?xml version=\"1.0\" encoding=\"utf-8\"?>" + new XMLSerializer().serializeToString(myXML);
           
          const xmlObject = {
            xml: newxmlString,
            type: this.state.formType,
            sr: this.state.sr
          };
          var resForLog = "";
          var clear = false;
          axios.post(backendURL+'/incident/create', xmlObject, {
            headers: {
              loginInfo: this.state.loginInfo
            }
          })
          .then((res) => {
            const entityImportReport = res.data["entity-import-report"];
            if (entityImportReport["entities-created"][0] === '1') {
              this.setState({
                toastContent: {
                  title: "Lähetetty onnistuneesti",
                  message: "Laitetiedot kirjattu",
                  style: "text-white bg-success"
                }
              })
              
              this.setShowToast(true);
              clear = true;
             
            }
            else if (entityImportReport["entities-updated"][0] === '1') {
              this.setState({
                toastContent: {
                  title: "Lähetetty onnistuneesti",
                  message: "Laitetiedot päivitetty",
                  style: "text-white bg-success"
                }
              })
              
              this.setShowToast(true);
              clear = true;
             
            }
            else if (entityImportReport["entities-created"][0] === '0' && entityImportReport["entities-updated"][0] === '0' ) {
              this.setState({
                toastContent: {
                  title: "Virhe!",
                  message: "Tämän laitteen tietoja ei voitu päivittää.",
                  style: "text-white bg-danger"
                }
              })
              this.setShowToast(true);
            }
            resForLog = "Response:"+'\n'+JSON.stringify(res.data);
          }).catch((error) => {
            alert(error)
          }).finally(() => {
            
            //Lähetetään logi lähetyksestä tai sen yrityksestä meittariin
            var logXML = document.implementation.createDocument("","",null);
            var entitysetLog = myXML.createElement("entityset");
            var entityLog = myXML.createElement("entity");
            var templateLog = myXML.createElement("template");
            templateLog.setAttribute("code",apiLog);
            entityLog.appendChild(templateLog);
            entityLog.appendChild(this.addNewAttributeValuePair(logXML,"import_data","<![CDATA["+newxmlString+"]]>"));
          
            entityLog.appendChild(this.addNewAttributeValuePair(logXML,"application_user_id",this.props.loggedInName));
            entityLog.appendChild(this.addNewAttributeValuePair(logXML,"import_response","<![CDATA["+resForLog+"]]>"));
            entitysetLog.appendChild(entityLog);
            logXML.appendChild(entitysetLog);
            var newLogxmlString = "<?xml version=\"1.0\" encoding=\"utf-8\"?>" + new XMLSerializer().serializeToString(logXML);
             
            const logXmlObject = {
              xml: newLogxmlString,
              type: apiLog
            };
            
            axios.post(backendURL+'/log/create', logXmlObject, {
              headers: {
                loginInfo: this.state.loginInfo
              }
            }).then((res)=>{
              //console.log(res.data)
            })
            if(clear) this.clearData();
          
          });
        });
        
        
      }
    }
    getData() {
      const that = this;
      axios.get(backendURL+'/'+"device_names_data", {
        headers: {
          loginInfo: this.state.loginInfo
        }
      })
      .then(function(data){
        var arr = [];

        data.data.map((num) => {
            arr.push(num)
        })
        that.setState({
          arrFinal: arr,
        })
      })
    }

    getDataSR() {
      const that = this;
      var allSR = []
      axios.get(backendURL+'/'+"basic_device_details", {
        headers: {
          loginInfo: this.state.loginInfo
        }
      })
      .then(function(datasr){
        datasr.data.map(sr_numbers => {
          allSR.push(sr_numbers[0])
        })
        that.setState({
        srArray: allSR,
        srDetails: datasr.data,
        isLoading: false
        })


    })}
    clearData() {
      this.setState ({
        value: '',
        host: '',
        sr: [],
        laite: 'empty',
        valittu2: false,
        laitetarkenne: 'empty',
        tyypintarkenne: 'empty',
        merkki: [],
        startDate: new Date(),
        singleSelections: [],
        singleSelectionsSR: []
      })

    }
    setShowToast(show) {
      this.setState({
        showToast: show
      })
    }
    componentDidMount() {
      this.setState({ isLoading: true });
      this.getData()
      this.getDataSR()
    }

render() {
  const { isLoading } = this.state;
  if (isLoading === true) {
    return <Spinner className= "spinnerStyles" animation="border" variant="primary"></Spinner>
  }

  return (
  <div className="Home">
    <h2>Laitetietojen ilmoitus</h2>

    <Toast className="spinnerStyles" onClose={() => this.setShowToast(false)} show={this.state.showToast} delay={5000} autohide>
        <Toast.Header className={this.state.toastContent.style}>
          <img
            src="holder.js/20x20?text=%20"
            className="rounded mr-2"
            alt=""
          />
          <strong className="mr-auto">{this.state.toastContent.title}</strong>
        </Toast.Header>
        <Toast.Body>{this.state.toastContent.message}</Toast.Body>
      </Toast>

    <form className="formLayout">

    <label>
    Laitteen tyyppi<br />
      <select value={this.state.laite} className="dropdownStyle" name="category" onChange={this.handleChangeLaite}>
        <option value="empty" >valitse</option>
        <option value="Työasema" >Työasema</option>
        <option value="Näyttö" >Näyttö</option>
        <option value="Telakka" >Telakka</option>
        <option value="Tulostin" >Tulostin</option>
        <option value="Mobiililaite" >Mobiililaite</option>
        <option value="AV-laite" >AV-laite</option>
      </select>
    </label>
    <>
    {this.state.laite === "Työasema" &&
    <>
    <br/>
      <label>Laitteen tyyppi laskutuksessa<br />
      <select value={this.state.laitetarkenne} className="dropdownStyle" name="category" onChange={this.handleChangeLaiteTarkenne}>
        <option value="empty" >valitse</option>
        <option value="Kannettava" >Kannettava</option>
        <option value="Pöytäkone" >Pöytäkone</option>
        <option value="Mac" >Mac</option>
        <option value="Chromebook" >Chromebook</option>
        <option value="Azure-tietokone" >Azure-tietokone</option>
        <option value="Thin" >Thin</option>
        <option value="Oppilas-tietokone" >Oppilas-tietokone</option>
      </select>
      </label>
      </>
    }
    {this.state.laite === "AV-laite" &&
    <>
    <br/>
    <label>Tyypin tarkenne<br/>
      <select value={this.state.tyypintarkenne} className="dropdownStyle" name="category" onChange={this.handleChangeTyypinTarkenne}>
        <option value="empty">valitse</option>
        <option value="Dataprojektori">Dataprojektori</option>
        <option value="Dokumenttikamera">Dokumenttikamera</option>
        <option value="Kosketusnäyttö">Kosketusnäyttö</option>
        <option value="AV-näyttö">AV-näyttö </option>
      </select>
    </label>
    </>
    }
    {this.state.laite === "Tulostin" &&
    <>
    <br/>
    <label>Laitteen tyyppi laskutuksessa<br />
      <select value={this.state.laitetarkenne} className="dropdownStyle" name="category" onChange={this.handleChangeLaiteTarkenne}>
        <option value="empty" >valitse</option>
        <option value="Oheiskirjoitin" >Oheiskirjoitin</option>
        <option value="Verkkokirjoitin" >Verkkokirjoitin</option>
      </select>
    </label>
    <br/>
    <label>Tyypin tarkenne<br/>
      <select value={this.state.tyypintarkenne} className="dropdownStyle" name="category" onChange={this.handleChangeTyypinTarkenne}>
        <option value="empty">valitse</option>
        <option value="Kuittikirjoitin">Kuittikirjoitin</option>
        <option value="Monitoimilaite">Monitoimilaite</option>
        <option value="Rannekekirjoitin">Rannekekirjoitin</option>
        <option value="Suurkokokirjoitin">Suurkokokirjoitin</option>
        <option value="Viivakoodikirjoitin">Viivakoodikirjoitin</option>
      </select>
    </label>
    <br/>
    </>
    }
    </>

    <Form.Group>
     Sarjanumero
     <FormControl className="inputStyles"
        type="text" value={this.state.value} onChange={this.handleChangeSerial} placeholder="123456789" />
    </Form.Group>

    {this.state.laite !== "Mobiililaite" && this.state.laite !== "AV-laite" &&
    <Form.Group>
    host-name / laitetarra
    <FormControl className="inputStyles"
          type="text" value={this.state.host} onChange={this.handleChangeHost} placeholder="nimi"/>
    </Form.Group>
    }
    {this.state.laite === "Tulostin" && this.state.laitetarkenne === "Verkkokirjoitin" &&
    <Form.Group>
    MAC-osoite
    <FormControl className="inputStyles"
          type="text" value={this.state.MAC} onChange={this.handleChangeMAC} placeholder="MAC-osoite"/>
    </Form.Group>
    }

    <Form.Group>
      Laitteen merkki/malli
        <Typeahead
          className="typeaheadStyle"
          id="custom-selections-example"
          newSelectionPrefix="Lisää uusi laite: "
          onChange={this.setSingleSelections}
          options={this.state.arrFinal}
          placeholder="Valitse tai kirjoita"
          paginationText="Näytä lisää vaihtoehtoja"
          selected={this.state.singleSelections}/>
    </Form.Group>

    <Form.Group>
    SR-numero
        <Typeahead
          className="typeaheadStyle"
          id="custom-selections-example"
          onChange={this.setSingleSelectionsSR}
          options={this.state.srArray}
          placeholder="Valitse tai kirjoita"
          paginationText="Näytä lisää vaihtoehtoja"
          selected={this.state.singleSelectionsSR}/>
    </Form.Group>

    <Form.Group>
    Asennuspäivämäärä<br />
    <DatePicker dateFormat="dd.MM.yyyy"
    className="dateStyle" selected={this.state.startDate} onChange={(date) => this.setStartDate(date)} />
    </Form.Group>

  <Button type="button" onClick={this.handleSubmit}>Lähetä</Button>
  </form><br />
  </div>
  );
}
}
export default Home;
